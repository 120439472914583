import React from "react";
import styled, { ThemeProvider } from "styled-components";
import RoomContext from "./utils/RoomContext";
import { chunk } from "lodash-es";
import { safeLoad } from "js-yaml";
import ReactMarkdown from "react-markdown";
import Action from "./utils/Action";

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  min-height: 100vh;

  font-family: "Noto Serif", "Times New Roman", serif;
  font-size: 14px;
  line-height: 1.6;
  text-align: justify;

  color: ${({ theme }) => theme.textColor};
  background: ${({ theme }) => theme.backgroundColor};

  @media (min-width: 600px) {
    font-size: 16px;
  }

  @media (min-width: 800px) {
    font-size: 18px;
  }
`;

const Button = styled.button`
  appearance: none;
  color: inherit;
  background: transparent;
  border: 1px solid ${({ theme }) => theme.textColor};
  font-size: 0.6em;
  padding: 0.3em 0.4em;
  cursor: pointer;

  opacity: 0.75;

  margin: 0 0.2em;
  :first {
    margin-left: 0;
  }
  :last {
    margin-right: 0;
  }
`;

const ToolBar = styled.div`
  padding: 10px 15px;
`;

const Inner = styled.div`
  max-width: 600px;
`;

interface Part {
  meta: {
    id: string;
  };
  text: string;
}

const App: React.FC = () => {
  const [darkTheme, setDarkTheme] = React.useState(!!localStorage.darkTheme);
  const [room, setRoom] = React.useState<string>(localStorage.room || "");
  const toggleDarkTheme = () => {
    if (darkTheme) {
      delete localStorage.darkTheme;
    } else {
      localStorage.darkTheme = true;
    }
    setDarkTheme(!darkTheme);
  };
  const [parts, setParts] = React.useState<Part[]>([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const data = await fetch("chapters/0.md");
      const raw = await data.text();
      const parts = chunk(raw.split("+++").slice(1), 2).map(([meta, text]) => ({
        meta: safeLoad(meta),
        text
      }));
      setParts(parts);
    };
    fetchData();
  }, []);

  const activePart = room ? parts.find(p => p.meta.id === room) : parts[0];

  const extra: any = [
    {
      parserOptions: {
        smart: true
      }
    }
  ];

  return (
    <ThemeProvider
      theme={{
        textColor: darkTheme ? "#fff" : "#111",
        hintColor: darkTheme ? "#ccc" : "#555",
        backgroundColor: darkTheme ? "#191919" : "#fafafa"
      }}
    >
      <Outer>
        <Inner>
          <RoomContext.Provider
            value={{
              room,
              setRoom(room: string) {
                localStorage.room = room;
                setRoom(room);
              }
            }}
          >
            <ReactMarkdown
              source={activePart && activePart.text}
              renderers={{
                link: Action
              }}
              {...extra}
            />
          </RoomContext.Provider>
        </Inner>
        <ToolBar>
          <Button onClick={toggleDarkTheme}>
            {darkTheme ? "Heller Modus" : "Dunkler Modus"}
          </Button>
          <Button
            onClick={() => {
              localStorage.room = "";
              setRoom("");
            }}
          >
            Neustarten
          </Button>
        </ToolBar>
      </Outer>
    </ThemeProvider>
  );
};

export default App;
