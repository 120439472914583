import React from "react";
import styled from "styled-components";
import RoomContext from "./RoomContext";

const Container = styled.span`
  border-bottom: 1px dashed ${({ theme }) => theme.hintColor};
  cursor: pointer;
`;

interface Props {
  href: string;
}

const Action: React.FC<Props> = ({ children, href }) => {
  const { setRoom } = React.useContext(RoomContext);

  return <Container onClick={() => setRoom(href)}>{children}</Container>;
};

export default Action;
